import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import Loading from "../../../../shared/components/Loader";
import { getJobLogs } from "../../../../redux/services/job_logs"
import JobLogRow from "./JobLogRow.jsx"

const JobHistoryTable = ({ jobId, onInvoicePressed }) => {
  const [logs, setLogs] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchJobsAndStopLoading();
  }, []);

  const fetchJobsAndStopLoading = () => {
    setLoad(true);
    getJobLogs(jobId).then((res) => {
      if(res && res.length){
        setLogs(res);
      }
      setLoad(false);
    });
  };

  if(load) return <Loading/>

  if(!logs || !logs.length || JSON.stringify(logs).includes('status code 400')) {
    return (
    <div className="w-100 d-flex justify-content-center">
    <p>No job logs found</p>
    </div>)
  }

  return (
    <>
    <Table
      responsive
      className="mt-3 table table--bordered pl-0"
    >
      <thead>
        <tr>
          <th>ACTION</th>
          <th>USER</th>
          <th>DATE</th>
        </tr>
      </thead>
      <tbody>
      {
        logs.sort((a, b) => b.id - a.id).map((log, index) => <JobLogRow key={index} item={log} onInvoicePressed={onInvoicePressed}/>)
      }
      </tbody>
    </Table>
    {/* {project && Math.ceil(project.total / pageSize) > 1 && (
      <div className="box-pagination">
        <Pagination
          count={
            project && project.total ? Math.ceil(project.total / pageSize) : 0
          }
          page={page + 1}
          onChange={(event, page) => setPage(page - 1)}
        />
      </div>
    )} */}
    </>
  );
};

export default withRouter(JobHistoryTable);
