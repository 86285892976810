import React,{useEffect, useState} from 'react';
import { withRouter, Link } from "react-router-dom";
import {Col, Row, Container, Button, Card, CardBody} from 'reactstrap';
import {SORT_BY} from "../../constants/enamerations";
import {generateInvoices, generateInvoicesTimeframe, generateInvoicesTimeframeForUser} from '../../redux/api/invoice';
import {invoiceToCsv} from "../../redux/services/invoice";
import {CalendarIcon} from '../../constants/icons';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import {getUsers} from '../../redux/services/users';
import {debounce} from 'lodash'

const index = () => {
    const [status, setStatus] = useState('');
    const [res, setRes] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [users, setUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(()=>{
        const userStatus = window.localStorage.getItem('STATUS');
        const userRes = window.localStorage.getItem('RES');
        if(userStatus !== null) setStatus(JSON.parse(userStatus));
        if(userRes !== null) setRes(JSON.parse(userRes));
    },[])

    useEffect(()=>{
        window.localStorage.setItem('STATUS', JSON.stringify(status));
        window.localStorage.setItem('RES', JSON.stringify(res));
    }, [status])

    const onChangeDateRange = (dates) => {
        setDateRange(dates)
    };

    const handleClick = () =>{
        generateInvoices().then(response => {
            if(response && response.length){
                setRes(response);
                setStatus(`${response.length} invoices were created in projects:`)
            }
            else setStatus("There are no jobs completed since the last invoice generation.")
        })
    }

    const handleClickCustom = () => {
        const [startDate, endDate] = dateRange;

        if (selectedUser && selectedUser.value !== -1) {
            generateInvoicesTimeframeForUser(selectedUser.value, startDate, endDate).then(response => {
                if(response && response.length){
                    setRes(response);
                    setStatus(`${response.length} invoices were created in projects:`)
                }
                else setStatus("There are no jobs completed since the last invoice generation.")
            })
        } else {
            generateInvoicesTimeframe(startDate, endDate).then(response => {
                if(response && response.length){
                    setRes(response);
                    setStatus(`${response.length} invoices were created in projects:`)
                }
                else setStatus("There are no jobs completed since the last invoice generation.")
            });
        }
    }

    const getAllUsers = debounce((e) => {
        getUsers(0, {text: e}).then((res) => {
            setUsers(res.items);
        });
    }, 500);

    const getUserValues = () => {
        if (!users) return [];
        return [{value: -1, label: 'All'}, ...users.map((u) => {
            return {
                label: u.first_name + ' ' + u.surname,
                value: u.id
            }
        })];
    }


    const getAllUnpaidInvoices = () => {
        invoiceToCsv([]).then(() => {})
    };
    const [startDate, endDate] = dateRange;
    return (
        <Container>
            <Row className="pr-3 d-flex justify-content-between">
                <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                    <Col md={1.5} xl={1.5} className="p-0">
                        <h5 className="page-title m-0">Invoices</h5>
                    </Col>
                </Col>
            </Row>
                <Button
                    className="btn_with_img"
                    color="primary"
                    onClick={handleClick}>
                    Generate Invoices
                </Button>
            <div className={'mt-3'}>
                <Button
                    className="btn_with_img"
                    color="primary"
                    onClick={getAllUnpaidInvoices}>
                    Export all unpaid invoices
                </Button>
            </div>
            <Card>
                <CardBody>
                    <Row className="pr-3 d-flex justify-content-between">
                        <Col md={5} xl={5} className="d-flex align-items-center mb-4">
                            <Col md={1.5} xl={1.5} className="p-0">
                                <h5 className="page-title m-0">Export invoices within timeframe</h5>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className="d-flex justify-content-between">
                            <div className="table-select-date-input" >
                                <div className={"d-flex flex-row align-items-center"}>
                                    <CalendarIcon />
                                    <div className={'d-flex flex-column align-items-start pl-1'}>
                                        <ReactDatePicker
                                            onChange={onChangeDateRange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Start Date - End Date"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className="d-flex justify-content-between mt-2">
                            <div className="table-select-date-input" >
                                <div className={"flex-row align-items-center flex-grow-1 d-block"}>
                                    <div className={'flex-column align-items-start pl-1'}>
                                        <Select
                                            placeholder={"Users"}
                                            onChange={(e) => setSelectedUser(e)}
                                            options={getUserValues()}
                                            onInputChange={(e) => getAllUsers(e)}
                                            classNamePrefix="react-select"
                                            className="w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} className="d-flex justify-content-between mt-2">
                            <Button
                                className="btn_with_img"
                                color="primary"
                                onClick={handleClickCustom}>
                                Generate Invoices
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <h5>{status}
                {status !== "There are no jobs completed since the last invoice generation." && res && res.map((currentItem, currentIndex, array) => {
                if(array[currentIndex+1])
                    return <Link
                    to={{pathname: `/projects/preview-project/${currentItem.project_id}/invoices`,
                    state: {sortBy: SORT_BY.PROJECT.value}}}> {currentItem.project_name}, </Link>
                else return <Link
                    to={{pathname: `/projects/preview-project/${currentItem.project_id}/invoices`,
                    state: {sortBy: SORT_BY.PROJECT.value}}}> {currentItem.project_name}</Link>
                }

                )}
                </h5>

        </Container>
    )
}

export default withRouter(index);
